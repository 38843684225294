import {Severity} from '@sentry/types/dist/severity';
import {Contexts} from '@sentry/types';
import {TagsBucket} from '@wix/error-monitor/dist/types/types';
import {HttpError} from '@wix/http-client';

export const toMonitorError = (
  error: unknown,
  description?: string
): [
  exception: Error,
  options?: {
    level?: Severity;
    contexts?: Contexts;
    tags?: TagsBucket;
  }
] => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const errorMessage = `Error: ${JSON.stringify((error as HttpError)?.response?.data.message)}`;
  return [
    new Error(description ? `${description} ${errorMessage}` : errorMessage),
    {
      tags: {tagName: errorMessage},
      level: Severity.Error,
      contexts: {
        context: {
          fullError: JSON.stringify(error as Error),
        },
      },
    },
  ];
};
