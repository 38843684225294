import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {PaymentRequestNavigationService} from '../../services/paymentRequest/PaymentRequestNavigationService';
import {
  PaymentRequestErrorCode,
  PaymentRequestNavigationStoreProps,
  PaymentRequestState,
} from '../../../types/paymentRequestApp.types';
import {PaymentRequestService} from '../../services/paymentRequest/PaymentRequestService';
import {PaymentRequestStatus} from '../../models/paymentRequest/PaymentRequest.model';
import {THANK_YOU_PAGE_PATH} from '../../../components/PaymentRequest/constants';
import {IWidgetControllerConfig} from '@wix/native-components-infra';
import {SPECS} from '../../../common/constants';

export class PaymentRequestNavigationStore {
  private readonly navigationService: PaymentRequestNavigationService;
  private readonly paymentRequestService: PaymentRequestService;
  private readonly siteStore: SiteStore;
  private readonly updateComponent: () => void;
  private readonly isSSR: boolean;
  private readonly controllerConfig: IWidgetControllerConfig;

  constructor({
    navigationService,
    paymentRequestService,
    siteStore,
    updateComponent,
    isSSR,
    controllerConfig,
  }: {
    navigationService: PaymentRequestNavigationService;
    paymentRequestService: PaymentRequestService;
    siteStore: SiteStore;
    updateComponent: () => void;
    isSSR: boolean;
    controllerConfig: IWidgetControllerConfig;
  }) {
    this.navigationService = navigationService;
    this.paymentRequestService = paymentRequestService;
    this.siteStore = siteStore;
    this.updateComponent = updateComponent;
    this.isSSR = isSSR;
    this.controllerConfig = controllerConfig;
  }

  private readonly navigateToThankYouPage = () => {
    this.navigationService.setPaymentRequestState(PaymentRequestState.paid);
    this.updateComponent();
  };

  private readonly navigateToLinkExpiredPage = () => {
    this.navigationService.setPaymentRequestState(PaymentRequestState.expired);
    this.updateComponent();
  };

  private readonly navigateBackToPaymentRequest = () => {
    const url = new URL(this.siteStore.location.url);
    url.searchParams.delete('redirect');

    this.siteStore.navigateToLink({
      url: url.toString(),
      sdkLink: {type: 'ExternalLink', target: '_self', url: url.toString()},
    });
  };

  public calculateInitialPaymentRequestStatus() {
    let state = PaymentRequestState.unpaid;
    const paymentRequestError = this.paymentRequestService.paymentRequestError;
    if (paymentRequestError) {
      if (paymentRequestError === PaymentRequestErrorCode.notFound) {
        state = PaymentRequestState.empty;
      }

      if (paymentRequestError === PaymentRequestErrorCode.generalError) {
        state = PaymentRequestState.error;
      }
    } else {
      if (
        this.siteStore.location.path[2] === THANK_YOU_PAGE_PATH ||
        this.paymentRequestService.paymentRequest.status === PaymentRequestStatus.paid
      ) {
        state = PaymentRequestState.paid;
      }

      if (this.paymentRequestService.paymentRequest.status === PaymentRequestStatus.expired) {
        state = PaymentRequestState.expired;
      }

      if (
        this.navigationService.redirectErrorState &&
        this.siteStore.experiments.enabled(SPECS.ShouldHandlePaylinksRedirectError)
      ) {
        state = PaymentRequestState.redirectError;
      }
    }

    this.navigationService.setPaymentRequestState(state);
    this.updateComponent();
  }

  public getPaymentRequestState() {
    return this.navigationService.paymentRequestState;
  }

  public toProps(): PaymentRequestNavigationStoreProps {
    return {
      continueShoppingUrl: this.navigationService.continueShoppingUrl,
      navigateToThankYouPage: this.navigateToThankYouPage,
      navigateToLinkExpiredPage: this.navigateToLinkExpiredPage,
      isSSR: this.isSSR,
      paymentRequestState: this.navigationService.paymentRequestState,
      trackEvent: (...args) => {
        this.controllerConfig.wixCodeApi.window.trackEvent(...args);
      },
      navigateBackToPaymentRequest: this.navigateBackToPaymentRequest,
      paymentMethodName: this.navigationService.paymentMethodName,
    };
  }
}
