import {ValueEnabled} from '@wix/wixstores-graphql-schema-node';

export class ValueEnabledModel {
  public enabled: boolean;
  public value: string;
  constructor(valueEnabled?: ValueEnabled | null) {
    this.enabled = !!valueEnabled?.enabled;
    this.value = valueEnabled?.value ?? '';
  }
}
