import {StoreCheckoutSettings} from '@wix/wixstores-graphql-schema-node';
import {query} from './getPaymentRequestSettings.graphql';
import {BaseApi, GraphQLOperations, RemoteSourceTypes} from '@wix/wixstores-client-storefront-sdk';

export class PaymentRequestSettingsApi extends BaseApi {
  public async getPaymentRequestSettings(): Promise<StoreCheckoutSettings> {
    const response = await this.fetch<Record<string, never>, {checkoutSettings: StoreCheckoutSettings}>(
      {
        query,
        variables: {},
        operationName: GraphQLOperations.GetPaymentRequestSettings,
        source: 'WixStoresWebClient',
      },
      RemoteSourceTypes.NodeReadWrite
    );

    return response.data.checkoutSettings;
  }
}
