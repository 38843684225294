/* istanbul ignore file */
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {RequestOptionsFactory} from '@wix/http-client/dist/src/types';

export const ambassadorWithHeaders = <T>(
  req: RequestOptionsFactory<T>,
  siteStore: SiteStore,
  flowAPI: ControllerFlowAPI,
  currency?: string
) => {
  const currencyHeader = currency ? {'x-wix-currency': currency} : {};
  const multilingualHeader = getMultilingualHeader(flowAPI, siteStore);
  const params = {headers: {...currencyHeader, ...multilingualHeader}};
  return flowAPI.essentials.httpClient.request((args) => ({...req(args), ...params}));
};

export const getMultilingualHeader = (flowAPI: ControllerFlowAPI, siteStore: SiteStore): Record<string, string> => {
  const currentLanguage = getCurrentLanguage(flowAPI);
  if (!currentLanguage) {
    return {};
  }

  return {
    'x-wix-linguist': `${currentLanguage.languageCode}|${
      currentLanguage.locale
    }|${currentLanguage.isPrimaryLanguage.toString()}|${siteStore.storeId}`,
  };
};

export const getCurrentLanguage = (flowAPI: ControllerFlowAPI) => {
  const {multilingual} = flowAPI.environment;
  if (!multilingual?.isEnabled) {
    return undefined;
  }

  return multilingual.siteLanguages.find((lang) => lang.languageCode === multilingual.currentLanguage);
};
