import {PaymentRequestModel} from '../../models/paymentRequest/PaymentRequest.model';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {
  checkoutPayingForExistingOrderCheckoutPageLoadedSrc130Evid40,
  checkoutPayingForExistingOrderClickOnPayNowSrc130Evid41,
  checkoutPayingForExistingOrderShowHideOverviewSectionMobileViewSrc130Evid42,
  checkoutPayingForExistingOrderThankYouPageLoadedSrc130Evid43,
  checkoutPayingForExistingOrderPaylinkExpiredPopupSrc130Evid44,
  checkoutPayingForExistingOrderErrorMessageSrc130Evid45,
  checkoutPayingForExistingOrderClickOnCheckoutPoliciesSrc130Evid47,
  checkoutPayingForExistingOrderClickGoToCheckoutInErrorMessageSrc130Evid22,
} from '@wix/bi-logger-ecom-platform-data/v2';
import {PaymentRequestSettingsService} from './PaymentRequestSettingsService';
import {toBiAmount} from '../../utils/bi.util';

export class PaymentRequestBIService {
  constructor(
    private readonly services: {
      siteStore: SiteStore;
      paymentRequestSettingsService: PaymentRequestSettingsService;
    }
  ) {}

  public checkoutPayingForExistingOrderCheckoutPageLoadedSrc130Evid40({
    paymentRequest,
  }: {
    paymentRequest: PaymentRequestModel;
  }): void {
    void this.services.siteStore.webBiLogger.report(
      checkoutPayingForExistingOrderCheckoutPageLoadedSrc130Evid40({
        orderId: paymentRequest.orderId,
        orderPaymentRequestId: paymentRequest.id,
        paymentRequestStatus: paymentRequest.status,
        amountLong: toBiAmount(paymentRequest.total.amount),
        currency: paymentRequest.currency,
        title: paymentRequest.title,
        description: paymentRequest.description,
        appId: paymentRequest.source?.appId,
        externalId: paymentRequest.source?.externalId,
        isPolicy: this.services.paymentRequestSettingsService.paymentRequestSettings.isThereAnyPolicyEnabled,
        expirationDate: paymentRequest.expirationDateTimeStamp,
      })
    );
  }

  public checkoutPayingForExistingOrderClickOnPayNowSrc130Evid41({
    paymentRequest,
    activePaymentId,
  }: {
    paymentRequest: PaymentRequestModel;
    activePaymentId?: string;
  }): void {
    void this.services.siteStore.webBiLogger.report(
      checkoutPayingForExistingOrderClickOnPayNowSrc130Evid41({
        orderId: paymentRequest.orderId,
        orderPaymentRequestId: paymentRequest.id,
        paymentRequestStatus: paymentRequest.status,
        amountLong: toBiAmount(paymentRequest.total.amount),
        currency: paymentRequest.currency,
        title: paymentRequest.title,
        description: paymentRequest.description,
        appId: paymentRequest.source?.appId,
        externalId: paymentRequest.source?.externalId,
        isPolicy: this.services.paymentRequestSettingsService.paymentRequestSettings.isThereAnyPolicyEnabled,
        expirationDate: paymentRequest.expirationDateTimeStamp,
        selectedPaymentProvider: activePaymentId,
      })
    );
  }

  public checkoutPayingForExistingOrderShowHideOverviewSectionMobileViewSrc130Evid42({
    paymentRequest,
    action,
  }: {
    paymentRequest: PaymentRequestModel;
    action: string;
  }): void {
    void this.services.siteStore.webBiLogger.report(
      checkoutPayingForExistingOrderShowHideOverviewSectionMobileViewSrc130Evid42({
        orderId: paymentRequest.orderId,
        orderPaymentRequestId: paymentRequest.id,
        appId: paymentRequest.source?.appId,
        externalId: paymentRequest.source?.externalId,
        action,
      })
    );
  }

  public checkoutPayingForExistingOrderThankYouPageLoadedSrc130Evid43({
    paymentRequest,
  }: {
    paymentRequest: PaymentRequestModel;
  }): void {
    void this.services.siteStore.webBiLogger.report(
      checkoutPayingForExistingOrderThankYouPageLoadedSrc130Evid43({
        orderId: paymentRequest.orderId,
        orderPaymentRequestId: paymentRequest.id,
        appId: paymentRequest.source?.appId,
        externalId: paymentRequest.source?.externalId,
        paymentRequestStatus: paymentRequest.status,
      })
    );
  }

  public checkoutPayingForExistingOrderPaylinkExpiredPopupSrc130Evid44({
    paymentRequest,
  }: {
    paymentRequest: PaymentRequestModel;
  }): void {
    void this.services.siteStore.webBiLogger.report(
      checkoutPayingForExistingOrderPaylinkExpiredPopupSrc130Evid44({
        orderId: paymentRequest.orderId,
        orderPaymentRequestId: paymentRequest.id,
        appId: paymentRequest.source?.appId,
        externalId: paymentRequest.source?.externalId,
        paymentRequestStatus: paymentRequest.status,
        expirationDate: paymentRequest.expirationDateTimeStamp,
      })
    );
  }

  public checkoutPayingForExistingOrderErrorMessageSrc130Evid45({
    paymentRequest,
    errorMessage,
    paymentRequestId,
  }: {
    paymentRequest: PaymentRequestModel;
    errorMessage: string;
    paymentRequestId?: string;
  }): void {
    void this.services.siteStore.webBiLogger.report(
      checkoutPayingForExistingOrderErrorMessageSrc130Evid45({
        orderId: paymentRequest?.orderId,
        orderPaymentRequestId: paymentRequest?.id ?? paymentRequestId,
        appId: paymentRequest?.source?.appId,
        externalId: paymentRequest?.source?.externalId,
        errorMessage,
        paymentRequestStatus: paymentRequest?.status,
      })
    );
  }

  public checkoutPayingForExistingOrderClickOnCheckoutPoliciesSrc130Evid47({
    paymentRequest,
    linkLocation,
    policyType,
  }: {
    paymentRequest: PaymentRequestModel;
    linkLocation: string;
    policyType: string;
  }): void {
    void this.services.siteStore.webBiLogger.report(
      checkoutPayingForExistingOrderClickOnCheckoutPoliciesSrc130Evid47({
        orderId: paymentRequest.orderId,
        orderPaymentRequestId: paymentRequest.id,
        appId: paymentRequest.source?.appId,
        externalId: paymentRequest.source?.externalId,
        linkLocation,
        policyType,
      })
    );
  }

  public checkoutPayingForExistingOrderClickGoToCheckoutInErrorMessageSrc130Evid22({
    paymentRequest,
  }: {
    paymentRequest: PaymentRequestModel;
  }): void {
    void this.services.siteStore.webBiLogger.report(
      checkoutPayingForExistingOrderClickGoToCheckoutInErrorMessageSrc130Evid22({
        orderId: paymentRequest.orderId,
        orderPaymentRequestId: paymentRequest.id,
        appId: paymentRequest.source?.appId,
        paymentRequestStatus: paymentRequest.status,
        externalId: paymentRequest.source?.externalId,
      })
    );
  }
}
