import {CheckoutHeader} from '@wix/wixstores-graphql-schema-node';
import {CheckoutHeaderLogoModel} from './CheckoutHeaderLogo.model';

export class CheckoutHeaderModel {
  public alignment?: string;
  public displayCheckoutLabel?: boolean;
  public logo?: CheckoutHeaderLogoModel;

  constructor(checkoutHeader?: CheckoutHeader) {
    this.alignment = checkoutHeader?.alignment ?? /* istanbul ignore next */ undefined;
    this.displayCheckoutLabel = checkoutHeader?.displayCheckoutLabel ?? /* istanbul ignore next */ false;
    this.logo = new CheckoutHeaderLogoModel(checkoutHeader?.logo ?? /* istanbul ignore next */ undefined);
  }
}
