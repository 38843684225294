// eslint-disable-next-line wix-custom-rules/wixstores-file-names
import {Source} from '@wix/ambassador-ecom-v1-order-payment-request/types';
import {mapNullToUndefined} from '../../utils/mapperHelper.utils';

export class PaymentRequestSource {
  public appId: string;
  public externalId: string;

  constructor(source?: Source) {
    this.appId = mapNullToUndefined(source?.appId) ?? '';
    this.externalId = mapNullToUndefined(source?.externalId) ?? '';
  }
}
