import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {PaymentRequestSettingsService} from '../../services/paymentRequest/PaymentRequestSettingsService';
import {PaymentRequestSettingsStoreProps} from '../../../types/paymentRequestApp.types';

export class PaymentRequestSettingsStore {
  private readonly siteStore: SiteStore;
  private readonly paymentRequestSettingsService: PaymentRequestSettingsService;

  constructor({
    siteStore,
    paymentRequestSettingsService,
  }: {
    siteStore: SiteStore;
    paymentRequestSettingsService: PaymentRequestSettingsService;
  }) {
    this.siteStore = siteStore;
    this.paymentRequestSettingsService = paymentRequestSettingsService;
  }

  public toProps(): PaymentRequestSettingsStoreProps {
    return {
      paymentRequestSettings: this.paymentRequestSettingsService.paymentRequestSettings,
    };
  }
}
