import {Image} from '@wix/ambassador-ecom-v1-order-payment-request/types';
import {mapNullToUndefined} from '../../utils/mapperHelper.utils';

export class PaymentRequestImageModel {
  public altText: string;
  public filename?: string;
  public height?: number;
  public width?: number;
  public src?: string;

  constructor(image: Image, title: string) {
    this.height = mapNullToUndefined(image.height);
    this.width = mapNullToUndefined(image.width);
    this.src = mapNullToUndefined(image.id);
    this.altText = mapNullToUndefined(image.altText) ?? title;
    this.filename = mapNullToUndefined(image.altText);
  }
}
