import {ValueEnabledTitle} from '@wix/wixstores-graphql-schema-node';

export class ValueEnabledTitleModel {
  public enabled: boolean;
  public value: string;
  public title: string;
  constructor(valueEnabled?: ValueEnabledTitle | null) {
    this.enabled = !!valueEnabled?.enabled;
    this.value = valueEnabled?.value ?? '';
    this.title = valueEnabled?.title ?? '';
  }
}
